<template>
	<div>
		<pui-field-set :title="this.$t('aqvesselcallsconsumption.additionaldata')">
			<v-container fluid class="pa-5 border-0">
				<v-row no-gutters>
					<v-col v-for="(col, index) in row1" :key="index">
						<v-card class="pa-2 my-1 border-0" outlined tile>
							<b>{{ $t(col.text) }}</b>
							<span>{{ col.value }}T</span>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</pui-field-set>
	</div>
</template>

<script>
import { search } from '../../api/common';

export default {
	name: 'consumption',
	props: ['id'],
	components: {},
	data() {
		return {
			tab: 1,
			loading_datatable: true,
			pollutant: null,
			consumption_id: null,
			map: null,
			row1: []
		};
	},
	mounted() {
		this.$root.$on('open-modal', this.openModal);
		console.log('I received id: ', this.id);
		this.getVesselCallConsumption(this.id);
		// this.getVesselcallsRoutesMap(this.id);
	},
	methods: {
		getTitleAdditionalData() {
			return this.$t('aqvesselcallsconsumption.additionaldata');
		},
		async getVesselCallConsumption(id) {
			const filters = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'aq_vessel_calls_id', op: 'eq', data: id }]
			};
			const body = {
				model: 'aqvesselcallconsumptions',
				filter: filters
			};

			let { data } = await search(body);
			if (data.data.length > 0) {
				this.row1 = [
					{ text: this.$t('aqvesselcallsconsumption.consumptionman'), value: this.roundDown(data.data[0].totalconsumptionman, 3) },
					{ text: this.$t('aqvesselcallsconsumption.consumptionhot'), value: this.roundDown(data.data[0].totalconsumptionhot, 3) },
					{ text: this.$t('aqvesselcallsconsumption.consumptionanch'), value: this.roundDown(data.data[0].totalconsumptionanch, 3) },
					{ text: this.$t('aqvesselcallsconsumption.consumptiontotal'), value: this.roundDown(data.data[0].totalconsumption, 3) }
				];
			}
		},
		roundDown(number, decimals) {
			const factor = Math.pow(10, decimals);
			return Math.floor(number * factor) / factor;
		}
	},
	computed: {},
	beforeDestroy() {
		this.$root.$off('open-modal', this.openModal);
	}
};
</script>

<style scoped>
.form_title {
	font-size: 16px;
	color: black;
}
.form-legend {
	font-size: 16px;
	font-weight: bold;
	color: #166bf2;
}
.border-0 {
	border: none;
}
.no-margin {
	margin: 0 !important;
}
.full-width {
	max-width: 100%;
}
</style>
