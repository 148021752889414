<template>
	<div class="vesselcalls-map-control">
		<v-btn text class="btnzoom pa-0" @click="zoomIn">
			<v-icon class="fw-500" size="16">fak fa-line-more-zoom</v-icon>
		</v-btn>

		<v-btn text class="btnlevel fs-regular fw-500 pa-0">{{ zoom }}</v-btn>

		<v-btn text class="btnzoom pa-0" @click="zoomOut">
			<v-icon class="fw-500" size="16">fak fa-line-less-zoom</v-icon>
		</v-btn>
	</div>
</template>

<script>
//import constants from '@/utils/constants';

//import MapMeasureTool from '@/components/map/sections/map/subcomponents/MapMeasureTool';

export default {
	name: 'VesselCallsControlMap',
	data() {
		return {
			zoom: this.initial_zoom
		};
	},
	props: ['initial_zoom', 'map'],
	mounted() {
		if (this.map) {
			this.zoom = this.map.getView().getZoom();
			this.map.getView().on('change:zoom', this.updateZoom);
			this.updateZoom();
		}
	},
	beforeDestroy() {
		if (this.map) {
			this.map.getView().un('change:zoom', this.updateZoom);
		}
	},
	components: {
		//MapMeasureTool
	},
	watch: {},
	methods: {
		zoomIn() {
			if (this.map) {
				const currentZoom = this.map.getView().getZoom();
				this.map.getView().setZoom(currentZoom + 0.5);
				this.updateZoom();
			}
		},
		zoomOut() {
			if (this.map) {
				const currentZoom = this.map.getView().getZoom();
				this.map.getView().setZoom(currentZoom - 0.5);
				this.updateZoom();
			}
		},
		updateZoom() {
			if (this.map) {
				this.zoom = this.map.getView().getZoom();
			}
		}
	}
};
</script>

<style scoped>
.vesselcalls-map-control {
	position: absolute;
	bottom: 10px;
	right: 10px;
	z-index: 1000;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 5px;
}
.vesselcalls-map-control .btnzoom {
	margin: 0 3px;
	padding: 3px 8px;
}
.btnzoom {
	margin: 0px;
	width: 32px;
	min-width: 32px !important;
	height: 32px;
	background-color: var(--negative);
}
.btnlevel {
	margin: 0px;
	width: 34px;
	min-width: 34px !important;
	height: 32px;
	background-color: var(--negative);
	cursor: auto !important;
	font-family: var(--font-family-primary);

	&:hover {
		background: var(--negative) !important;
	}
	&:focus {
		background: var(--negative) !important;
	}
}
</style>
