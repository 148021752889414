<template>
	<div>
		<v-tabs v-model="tab" align-tabs="center" color="deep-purple-accent-4">
			<v-tab :value="0" v-show="false"></v-tab>
			<v-tab :value="1">CO2</v-tab>
			<v-tab :value="2">NOX</v-tab>
		</v-tabs>

		<v-tabs-items v-model="tab">
			<v-tab-item :value="1">
				<pui-field-set :title="this.$t('aqvesselcallspollutant.additionaldata')">
					<v-container fluid class="pa-5 border-0">
						<v-row no-gutters>
							<v-col v-for="(col, index) in currentTabData.row1" :key="index">
								<v-card class="pa-2 my-1 border-0" outlined tile>
									<b>{{ $t(col.text) }}</b>
									<span>{{ col.value }}T</span>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</pui-field-set>
				<legend
					class="form-legend pa-2 ms-3"
					:title="$t('aqvesselcallspollutant.routeSection')"
					v-html="$t('aqvesselcallspollutant.routeSection')"
				></legend>
				<v-container class="border-0 no-margin full-width">
					<v-row no-gutters align="center" justify="center">
						<v-col style="width: 40%">
							<v-card class="pa-2" style="border: none" outlined tile>
								<v-data-table
									:headers="getHeaders()"
									:items="currentTabData.datasections"
									height="315px"
									hide-default-footer
									:items-per-page="99"
									item-key="aqroutesectionid"
									disable-sort
									:loading="loadingAnimation"
									loading-text="Loading..."
								></v-data-table>
							</v-card>
						</v-col>
						<v-col style="width: 60%; height: 325px">
							<v-card style="height: 325px; border: none" outlined tile>
								<Map :data="currentTabData.sections" :key="tab"></Map>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-tab-item>

			<v-tab-item :value="2">
				<pui-field-set :title="this.$t('aqvesselcallspollutant.additionaldata')">
					<v-container fluid class="pa-5 border-0">
						<v-row no-gutters>
							<v-col v-for="(col, index) in currentTabData.row1" :key="index">
								<v-card class="pa-2 my-1 border-0" outlined tile>
									<b>{{ $t(col.text) }}</b>
									<span>{{ col.value }}T</span>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</pui-field-set>
				<legend
					class="form-legend pa-2 ms-3"
					:title="$t('aqvesselcallspollutant.routeSection')"
					v-html="$t('aqvesselcallspollutant.routeSection')"
				></legend>
				<v-container class="border-0 no-margin full-width">
					<v-row no-gutters align="center" justify="center">
						<v-col style="width: 40%">
							<v-card class="pa-2" style="border: none" outlined tile>
								<v-data-table
									:headers="getHeaders()"
									:items="currentTabData.datasections"
									height="315px"
									hide-default-footer
									:items-per-page="99"
									item-key="aqroutesectionid"
									disable-sort
									:loading="loadingAnimation"
									loading-text="Loading..."
								></v-data-table>
							</v-card>
						</v-col>
						<v-col style="width: 60%; height: 325px">
							<v-card style="height: 325px; border: none" outlined tile>
								<Map :data="currentTabData.sections" :key="tab"></Map>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import { search } from '../../api/common';
import Map from './AqVesselCallsMap.vue';

export default {
	name: 'pollutant',
	props: ['id'],
	components: {
		Map
	},
	data() {
		return {
			tab: 1,
			loading_datatable: true,
			pollutant: null,
			consumption_id: null,
			map: null,
			loadingAnimation: true,
			tabData: {
				1: {
					row1: [],
					datasections: [],
					sections: []
				},
				2: {
					row1: [],
					datasections: [],
					sections: []
				}
			}
		};
	},
	computed: {
		currentTabData() {
			return this.tabData[this.tab];
		},
		loadingDialog: {
			get() {
				return this.$store.state.misc.loadingDialog;
			},
			set(value) {
				this.$store.state.misc.loadingDialog = value;
			}
		}
	},
	mounted() {
		this.$root.$on('open-modal', this.openModal);
		console.log('I received id: ', this.id);
		console.debug('debug mounted', this.loadingAnimation, this.loadingDialog);
		this.loadingAnimation = this.loadingDialog = true;
		this.getVesselCallConsumption(this.id);
	},
	updated() {
		console.debug('debug updated', this.loadingAnimation, this.loadingDialog);
	},
	beforeDestroy() {
		this.$root.$off('open-modal', this.openModal);
	},
	methods: {
		async getVesselCallConsumption(id) {
			const filters = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'aq_vessel_calls_id', op: 'eq', data: id }]
			};
			const body = {
				model: 'aqvesselcallconsumptions',
				filter: filters
			};

			let { data } = await search(body);
			if (data.data.length > 0) {
				this.consumption_id = data.data[0].aqvesselcallconsumptionsid;
			}
			this.getVesselCallEmissions(this.consumption_id);
		},
		async getVesselCallEmissions(id) {
			const filters = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'aq_vessel_call_consumptions_id', op: 'eq', data: id }]
			};

			const body = {
				model: 'aqvesselcallemissionspollutantversion',
				filter: filters
			};
			let { data } = await search(body);
			for (let i = 0; i < data.data.length; i++) {
				switch (data.data[i].mpollutant) {
					case 'CO2':
						this.getVesselcallsRoutes(this.id, 1, data.data[i].mpollutant);
						this.getVesselcallsRoutesMap(this.id, 1, data.data[i].mpollutant);
						this.populateData(1, data.data[i]);
						break;
					case 'NOX':
						this.getVesselcallsRoutes(this.id, 2, data.data[i].mpollutant);
						this.getVesselcallsRoutesMap(this.id, 2, data.data[i].mpollutant);
						this.populateData(2, data.data[i]);
						break;
				}
			}
		},
		populateData(tab, data) {
			this.tabData[tab].row1 = [
				{ text: this.$t('aqvesselcallspollutant.emissionman'), value: this.roundDown(data.totalemissionsman, 3) },
				{ text: this.$t('aqvesselcallspollutant.emissionhot'), value: this.roundDown(data.totalemissionshot, 3) },
				{ text: this.$t('aqvesselcallspollutant.emissionanch'), value: this.roundDown(data.totalemissionsanch, 3) },
				{ text: this.$t('aqvesselcallspollutant.emissiontotal'), value: this.roundDown(data.totalemission, 3) }
			];
			//console.log("Tab data is... :", this.tabData)
		},
		roundDown(number, decimals) {
			const factor = Math.pow(10, decimals);
			return Math.floor(number * factor) / factor;
		},
		async getVesselcallsRoutes(id, tab, pollutant) {
			const filterByActivePoints = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'aq_vessel_calls_id', op: 'eq', data: id },
					{ field: 'aq_pollutants_id', op: 'eq', data: pollutant }
				]
			};

			const body = {
				model: 'aqvvesselcallsroutes',
				filter: filterByActivePoints,
				rows: 200
			};

			let { data } = await search(body);
			this.tabData[tab].datasections = data.data;
			this.loadingAnimation = false;
			//console.log("Routes data: ", this.tabData[this.tab].datasections);
		},
		async getVesselcallsRoutesMap(id, tab, pollutant) {
			const filterByActivePoints = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'aq_vessel_calls_id', op: 'eq', data: id },
					{ field: 'aq_pollutants_id', op: 'eq', data: pollutant }
				]
			};

			const body = {
				model: 'aqvvesselcallsroutesmap',
				filter: filterByActivePoints,
				rows: 200
			};
			try {
				let { data } = await search(body);
				console.log('Vesselcalls routes map data: ', data.data);
				let newArray = [];
				for (let i = 0; i < data.data.length; i++) {
					let datanecesaria = [data.data[i].lon, data.data[i].lat, data.data[i].totalemissionssection];
					newArray.push(datanecesaria);
				}
				this.tabData[tab].sections = newArray;
			} catch {
				let data = [];
				this.tabData[tab].sections = data;
			}
			this.loadingAnimation = false;
		},
		getHeaders() {
			let headerKeys = ['aqphasesid', 'durationsection', 'totalemissionssection'];

			const mappedArray = headerKeys.map((key) => {
				return { text: this.$t('aqvesselcallspollutant.' + key), value: key, class: 'expanded_headers_style', sortable: false };
			});
			return mappedArray;
		}
	}
};
</script>

<style scoped>
.form_title {
	font-size: 16px;
	color: black;
}
.form-legend {
	font-size: 16px;
	font-weight: bold;
	color: #166bf2;
}
.border-0 {
	border: none;
}
.no-margin {
	margin: 0 !important;
}
.full-width {
	max-width: 100%;
}
</style>
